<template>
  <div class="end" :style="containerStyle">
    <img :src="info.titleImage ? info.titleImage : ''" class="head" />
    <!-- <p class="title">
      {{ info.title }}
    </p> -->
    <div class="body">
      <p class="body-title"> 
        {{ info.closingTitle }}
      </p>
      <p class="body-description">
        {{ info.closingContent }}
      </p>
      <!-- <p class="body-title">
        <span v-if="info.submitButton == '02'">Submitted successfully</span>
        <span v-else>提交成功！</span>
        </p>
      <p class="body-description">
        <span v-if="info.submitButton == '02'">
          Your membership shall be approved by IAISP Executive Committee and activated after membership fee remittance completed.
        </span>
        <span v-else>感谢您参与本次问卷调研。</span>
      </p> -->
      <!-- <van-button
        class="button"
        color="linear-gradient(90deg, #FF891C, #FF5508)"
        @click="close"
        >返回首页
      </van-button>
    --></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.info = this.$store.getters.getQuestionnaireInfo
    console.log(
      this.info
    );
  },
  computed: {
    containerStyle() {
      if (this.info.backgroundImage) {
        return { backgroundImage: `url('${this.info.backgroundImage}')` }
      } else {
        return { backgroundColor: this.info.backgroundColor }
      }
    },
  },
  methods: {
    close() {
      this.$router.push('/questionnaire/start')
    },
  },
}
</script>
<style lang="less" scoped>
.end {
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  .head {
    width: 100%;
  }
  .title {
    position: absolute;
    top: 0;
    color: #ffffff;
    font-size: 100px;
    text-align: center;
    top: 171px;
    width: 1094px;
    left: 89px;
    font-weight: 800;
  }
  .body {
    width: 1172px;
    height: 2058px;
    // height: 1172px;
    margin-left: 56px;
    background: #ffffff;
    border-radius: 56px;
    text-align: center;
    overflow: hidden;
    margin-top: 20px;
    &-title {
      font-size: 72px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #0e0e0e;
      margin-top: 400px;
      white-space:pre-wrap
    }
    &-description {
      margin-top: 80px;
      // width: 560px;
      // margin-left: 310px;
      text-align: center;
      font-size: 56px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #b5b5b6;
      line-height: 72px;
      white-space:pre-wrap
    }
    .button {
      position: absolute;
      z-index: 3;
      width: 997px;
      top: 1692px;
      left: 87px;
      border-radius: 80px;
    }
  }
}
</style>
